import React  from 'react';
import './App.css';
import AppListLang from './AppListLang.json';

class AppList extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			companyName: "",
		};

		this.renderOneRow = this.renderOneRow.bind(this);		
		this.handleRowClick = this.handleRowClick.bind(this);				
	}

	renderOneRow(title, appUserType, page) {

		if (appUserType < 1) {
			return null;
		}

		var gtString = ">";
		if (page === "") {
			gtString = "";
		}

		return (
				<div className="ViewRowContainer" onClick={() => this.handleRowClick(page)}>
					<div className="ViewRowTitle">{title}</div>
					<div className="ViewRowText">{page}</div>
					<div className="ViewRowGreater">{gtString}</div>
				</div>
		);
	}
	
	handleRowClick(page) {
		var sessionID = this.props.sessionID;
		var pageURL = page + "?sid=" + sessionID;
		const newWindow = window.open(pageURL, '_blank', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null
	}

	render() {

		//var userID = this.props.userID;
		var email = this.props.email;
		var firstName = this.props.firstName;
		var lastName = this.props.lastName;
		//var phone = this.props.phone;
		var managementAppUserType = this.props.managementAppUserType;
		var sailfinAppUserType = this.props.sailfinAppUserType;
		var katafinAppUserType = this.props.katafinAppUserType;
		var productionAppUserType = this.props.productionAppUserType;
		var metersAppUserType = this.props.metersAppUserType;
		var icartAppUserType = this.props.icartAppUserType;
		var serviAppUserType = this.props.serviAppUserType;
		var robotsAppUserType = this.props.robotsAppUserType;
		var guardsAppUserType = this.props.guardsAppUserType;
		var twinsAppUserType = this.props.twinsAppUserType;
		var rentAppUserType = this.props.rentAppUserType;
		var develAppUserType = this.props.develAppUserType;
		var aiAppUserType = this.props.aiAppUserType;
		var chatAppUserType = this.props.chatAppUserType;
		var replyAppUserType = this.props.replyAppUserType;
		//var companyName = this.props.companyName;

		return (
			<div>

				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">{this.props.getLang(AppListLang, "pluratoAccount")}</div>
				<div className="ViewTopText">{email} - {firstName} {lastName}</div>
				
				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">{this.props.getLang(AppListLang, "pluratoApps")}</div>
					<div style={{height: "20px"}}></div>
					{this.renderOneRow("Management", managementAppUserType, "https://management.plurato.com")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Sailfin App", sailfinAppUserType, "https://sailfinapp.plurato.com")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Rent App", rentAppUserType, "https://rent.plurato.com")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Katafin App", katafinAppUserType, "https://katafinapp.plurato.com")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Production App", productionAppUserType, "https://production.plurato.com")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Devel App", develAppUserType, "https://devel.plurato.com")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Chat App", chatAppUserType, "https://chat.plurato.com")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("AI App", aiAppUserType, "https://ai.plurato.com")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Reply App", replyAppUserType, "https://reply.plurato.com")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Meters App", metersAppUserType, "https://meters.plurato.com")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("iCart App", icartAppUserType, "https://icart.plurato.com")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Servi App", serviAppUserType, "https://servi.plurato.com")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Robots App", robotsAppUserType, "https://robots.plurato.com")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Guards App", guardsAppUserType, "https://guards.plurato.com")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow("Twins App", twinsAppUserType, "https://twins.plurato.com")}
					<div style={{height: "10px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default AppList;


