import React  from 'react';
import './App.css';
import MyInfoLang from './MyInfoLang.json';
  
import { useNavigate } from "react-router-dom";

class MyInfoPassword extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			oldPassword: "",
			newPassword1: "",
			newPassword2: "",
		};

		this.onOldPasswordChanged = this.onOldPasswordChanged.bind(this);		
		this.onNewPassword1Changed = this.onNewPassword1Changed.bind(this);		
		this.onNewPassword2Changed = this.onNewPassword2Changed.bind(this);		
		this.serverUpdatePassword = this.serverUpdatePassword.bind(this);
		this.checkIfCurrentPasswordCorrectYN = this.checkIfCurrentPasswordCorrectYN.bind(this);
		this.checkIfCurrentPasswordCorrectYNResultFunction = this.checkIfCurrentPasswordCorrectYNResultFunction.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	componentDidMount(){
	}

	onOldPasswordChanged(value) {
		this.setState({oldPassword: value});
	}
	
	onNewPassword1Changed(value) {
		this.setState({newPassword1: value});
	}
	
	onNewPassword2Changed(value) {
		this.setState({newPassword2: value});
	}
	
	async serverUpdatePassword(userID, newPassword) {

		var managementBaseURLAPI = this.props.managementBaseURLAPI;
		var sessionID = this.props.sessionID;
		var url = managementBaseURLAPI + "/updateuserpassword?sid=" + sessionID;
		url = url + "&userid=" + encodeURI(userID);
		url = url + "&password=" + encodeURI(newPassword);

		const res = await fetch(url);
		await res.text();
	}
	
	async checkIfCurrentPasswordCorrectYN(password, resultFunction) {

		var managementBaseURLAPI = this.props.managementBaseURLAPI;
		var sessionID = this.props.sessionID;
		var url = managementBaseURLAPI + "/checkpassword?sid=" + sessionID;
		url = url + "&password=" + encodeURI(password);
		
		const res = await fetch(url);
		var textResponse = await res.text();
		var correctYN = false;
		if (textResponse === "true") {
			correctYN = true;
		}
		resultFunction(correctYN);
	}

	checkIfCurrentPasswordCorrectYNResultFunction(correctYN) {
		//console.log("correctYN = " + correctYN);
		if (correctYN) {
			var userID = this.props.userID;
			this.serverUpdatePassword(userID, this.state.newPassword1);
			this.props.showToast(this.props.getLang(MyInfoLang, "newPasswordSaved"));			
			const navigate = this.props.navigate;
			navigate("/myaccount");
		} else {
			this.props.showToast(this.props.getLang(MyInfoLang, "currentPasswordIncorrect"));			
		}
	}

	onClickSaveButton() {
		
		if (this.state.newPassword1 === "") {
			this.props.showToast(this.props.getLang(MyInfoLang, "passwordCannotBeEmpty"));
			return;
		}

		if (this.state.newPassword1 !== this.state.newPassword2) {
			this.props.showToast(this.props.getLang(MyInfoLang, "passwordsDoNotMatch"));			
			return;
		}
		
		this.checkIfCurrentPasswordCorrectYN(this.state.oldPassword, this.checkIfCurrentPasswordCorrectYNResultFunction);
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/myaccount");
	}
	
	render() {

		var saveButtonYN = true;

		if (this.state.newPassword1 !== "") {
			//saveButtonYN = true;
		}

		return (
			<div className="ViewContainer">
			
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">{this.props.getLang(MyInfoLang, "updateYourPassword")}</div>
				<div className="ViewTopText">{this.props.getLang(MyInfoLang, "updateYourPasswordMessage")}</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">{this.props.getLang(MyInfoLang, "password")}</div>
					<div style={{height: "20px"}}></div>
					<div className="ViewItemContainer">
						<div style={{height: "20px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(MyInfoLang, "currentPassword")}</legend> 
							<input
								className="ViewItemInput" 
								id="oldPassword" 
								type="password" 
								autoFocus={true}
								defaultValue=""
								onChange={(event) => this.onOldPasswordChanged(event.target.value)}
							/>
						</fieldset>
						<div style={{height: "20px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(MyInfoLang, "newPasswordFirstTime")}</legend> 
							<input
								className="ViewItemInput" 
								id="newPassword1" 
								type="password" 
								defaultValue=""
								onChange={(event) => this.onNewPassword1Changed(event.target.value)}
							/>
						</fieldset>
						<div style={{height: "20px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(MyInfoLang, "newPasswordSecondTime")}</legend> 
							<input
								className="ViewItemInput" 
								id="newPassword2" 
								type="password" 
								defaultValue=""
								onChange={(event) => this.onNewPassword2Changed(event.target.value)}
							/>
						</fieldset>
					</div>
					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(MyInfoLang, "cancelButton")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton} disabled={!saveButtonYN}>{this.props.getLang(MyInfoLang, "saveButton")}</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function MyInfoPasswordFunction(props) {
	const navigate = useNavigate();
	return <MyInfoPassword {...props} navigate={navigate} />;
}


