import * as React from 'react';
import './App.css';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";

import Topbar from './common/Topbar.js';
import Login from './Login.js';
import AppList from './AppList.js';
import MyInfo from './MyInfo.js';
import MyInfoPassword from './MyInfoPassword.js';
import MyInfoName from './MyInfoName.js';
import MyInfoPhone from './MyInfoPhone.js';

class App extends React.Component {

	constructor(props) {

		super(props);

		this.state = {
			managementBaseURLAPI: "https://managementapi.plurato.com",
			sessionID: "",
			userID: 0,
			email: "",
			firstName: "",
			lastName: "",
			phone: "",
			language: "en",
			managementAppUserType: 0,
			sailfinAppUserType: 0,
			katafinAppUserType: 0,
			productionAppUserType: 0,
			metersAppUserType: 0,
			icartAppUserType: 0,
			serviAppUserType: 0,			
			robotsAppUserType: 0,
			guardsAppUserType: 0,
			twinsAppUserType: 0,
			rentAppUserType: 0,
			develAppUserType: 0,
			aiAppUserType: 0,
			chatAppUserType: 0,
			replyAppUserType: 0,
			firstLoadYN: true,
		}

		this.serverGetUserDataFromSessionID = this.serverGetUserDataFromSessionID.bind(this);
		this.getSessionIDFromURLString = this.getSessionIDFromURLString.bind(this);
		this.serverUpdateUserLanguage = this.serverUpdateUserLanguage.bind(this);
		this.showToast = this.showToast.bind(this);
		this.logoutClearVariables = this.logoutClearVariables.bind(this);
		this.serverLogout = this.serverLogout.bind(this);
		this.setCompanyName = this.setCompanyName.bind(this);
		this.serverGetUserData = this.serverGetUserData.bind(this);
		this.setSessionID = this.setSessionID.bind(this);
		this.setUserID = this.setUserID.bind(this);
		this.setLanguage = this.setLanguage.bind(this);
		this.getLang = this.getLang.bind(this);
	}
	
	componentDidMount() {

		document.title = "Plurato Apps"

		var windowLocation = window.location;
		var sessionID = this.getSessionIDFromURLString(windowLocation.toString());
		
		if (sessionID === "") {
			this.setState({firstLoadYN: false});
			return;
		}
		
		this.setState({
			sessionID: sessionID,
		});
		this.serverGetUserDataFromSessionID(sessionID);
	}

	async serverGetUserDataFromSessionID(sessionID) {
		
		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getuserdatafromsessionid?sid=" + sessionID;

		try {
			const res = await fetch(url);
			const jsonObj = await res.json();

			var userID = jsonObj.userID;
			var email = jsonObj.email;
			var firstName = jsonObj.firstName;
			var lastName = jsonObj.lastName;
			var phone = jsonObj.phone;
			var language = jsonObj.language;
			var managementAppUserType = jsonObj.managementAppUserType;
			var sailfinAppUserType = jsonObj.sailfinAppUserType;
			var katafinAppUserType = jsonObj.katafinAppUserType;
			var productionAppUserType = jsonObj.productionAppUserType;
			var metersAppUserType = jsonObj.metersAppUserType;
			var icartAppUserType = jsonObj.icartAppUserType;
			var serviAppUserType = jsonObj.serviAppUserType;
			var robotsAppUserType = jsonObj.robotsAppUserType;
			var guardsAppUserType = jsonObj.guardsAppUserType;
			var twinsAppUserType = jsonObj.twinsAppUserType;
			var rentAppUserType = jsonObj.rentAppUserType;
			var develAppUserType = jsonObj.develAppUserType;
			var aiAppUserType = jsonObj.aiAppUserType;
			var chatAppUserType = jsonObj.chatAppUserType;
			var replyAppUserType = jsonObj.replyAppUserType;
			var companyID = jsonObj.companyID;
			var createdTimestamp = jsonObj.createdTimestamp;

			this.setState({
				userID: userID,
				email: email,
				firstName: firstName,
				lastName: lastName,
				phone: phone,
				language: language,
				managementAppUserType: managementAppUserType,
				sailfinAppUserType: sailfinAppUserType,
				katafinAppUserType: katafinAppUserType,
				productionAppUserType: productionAppUserType,
				metersAppUserType: metersAppUserType,
				icartAppUserType: icartAppUserType,
				serviAppUserType: serviAppUserType,
				robotsAppUserType: robotsAppUserType,
				guardsAppUserType: guardsAppUserType,
				twinsAppUserType: twinsAppUserType,
				rentAppUserType: rentAppUserType,
				develAppUserType: develAppUserType,
				aiAppUserType: aiAppUserType,
				chatAppUserType: chatAppUserType,
				replyAppUserType: replyAppUserType,
				companyID: companyID,
				createdTimestamp: createdTimestamp,
				firstLoadYN: false,
			});
		
			this.setCompanyName(companyID);
		} catch (e) {
			this.setState({firstLoadYN: false});
		}
	}
	
	getSessionIDFromURLString(urlString) {

		var i;
		var index;
		var length;
		var foundYN;
		var sessionID = "";
		
		length = urlString.length;
		foundYN = false;
		index = 0;
		
		// find sid=
		for(i=0; (i<length) && (!foundYN); i++) {
			if (urlString.substr(i, 4) === "sid=") {
				foundYN = true;
				index = i+4;
			}
		}
		
		if (!foundYN) {
			return "";
		}
		
		sessionID = urlString.substr(index, length-index);
		return sessionID;
	}
	
	async serverUpdateUserLanguage(userID, language) {

		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/updateuserlanguage?userid=" + userID + "&language=" + language;
		
		const res = await fetch(url);
		await res.text();
	}

	showToast(messageText) {
		toast(messageText, {
			position: "top-right",
			autoClose: 2500,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
		});
	}
	
	logoutClearVariables() {

		var sessionID = this.state.sessionID;
		this.serverLogout(sessionID);

		this.setState({
			sessionID: "",
			userID: 0,
			email: "",
			firstName: "",
			lastName: "",
			phone: "",
			managementAppUserType: 0,
			sailfinAppUserType: 0,
			katafinAppUserType: 0,
			productionAppUserType: 0,
			metersAppUserType: 0,
			icartAppUserType: 0,
			serviAppUserType: 0,
			robotsAppUserType: 0,
			guardsAppUserType: 0,
			twinsAppUserType: 0,
			rentAppUserType: 0,
			develAppUserType: 0,
			aiAppUserType: 0,
			chatAppUserType: 0,
			replyAppUserType: 0,
			companyID: 0,
			createdTimestamp: "",
		});
	}

	async serverLogout(sessionID) {
		
		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/logout?sid=" + sessionID;

		const res = await fetch(url);
		await res.text();
	}

	async serverGetUserData(userID) {

		if (userID <= 0) {
			return;
		}

		var sessionID = this.state.sessionID;
		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getuserdata?sid=" + sessionID + "&userid=" + userID;
		
		const res = await fetch(url);
		const jsonObj = await res.json();

		var email = jsonObj.email;
		var firstName = jsonObj.firstName;
		var lastName = jsonObj.lastName;
		var phone = jsonObj.phone;
		var language = jsonObj.language;
		var managementAppUserType = jsonObj.managementAppUserType;
		var sailfinAppUserType = jsonObj.sailfinAppUserType;
		var katafinAppUserType = jsonObj.katafinAppUserType;
		var productionAppUserType = jsonObj.productionAppUserType;
		var metersAppUserType = jsonObj.metersAppUserType;
		var icartAppUserType = jsonObj.icartAppUserType;
		var serviAppUserType = jsonObj.serviAppUserType;
		var robotsAppUserType = jsonObj.robotsAppUserType;
		var guardsAppUserType = jsonObj.guardsAppUserType;
		var twinsAppUserType = jsonObj.twinsAppUserType;
		var rentAppUserType = jsonObj.rentAppUserType;
		var develAppUserType = jsonObj.develAppUserType;
		var aiAppUserType = jsonObj.aiAppUserType;
		var chatAppUserType = jsonObj.chatAppUserType;
		var replyAppUserType = jsonObj.replyAppUserType;
		var companyID = jsonObj.companyID;
		var createdTimestamp = jsonObj.createdTimestamp;

		this.setState({
			email: email,
			firstName: firstName,
			lastName: lastName,
			phone: phone,
			language: language,
			managementAppUserType: managementAppUserType,
			sailfinAppUserType: sailfinAppUserType,
			katafinAppUserType: katafinAppUserType,
			productionAppUserType: productionAppUserType,
			metersAppUserType: metersAppUserType,
			icartAppUserType: icartAppUserType,
			serviAppUserType: serviAppUserType,
			robotsAppUserType: robotsAppUserType,
			guardsAppUserType: guardsAppUserType,
			twinsAppUserType: twinsAppUserType,
			rentAppUserType: rentAppUserType,
			develAppUserType: develAppUserType,
			aiAppUserType: aiAppUserType,
			chatAppUserType: chatAppUserType,
			replyAppUserType: replyAppUserType,
			companyID: companyID,
			createdTimestamp: createdTimestamp,
		});
		
		this.setCompanyName(companyID);
	}
	
	async setCompanyName(companyID) {

		var managementBaseURLAPI = this.state.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/getcompanydata?companyid=" + companyID;

		const res = await fetch(url);
		const jsonObj = await res.json();

		var companyName = jsonObj.companyName;
		this.setState({companyName: companyName});
	}
	
	setSessionID(sessionID) {
		this.setState({sessionID: sessionID});
	}
	
	setUserID(userID) {
		this.setState({userID: userID});
	}
	
	setLanguage(language) {
		var userID = this.state.userID;
		this.serverUpdateUserLanguage(userID, language);
		this.setState({language: language});
	}
	
	getLang(lang, id) {
		var language = this.state.language;
		var id2 = id + "." + language;
		var i;
		var id3;
		var length = lang.length;
		var itemObj;
		var keys;
		var returnString;
		for(i=0; i<length; i++) {
			itemObj = lang[i];
			keys = Object.keys(itemObj);
			id3 = keys[0];
			if (id2 === id3) {
				returnString = itemObj[id2];
				return returnString;
			}
		}
		return "";
	}
	
	render() {

		var firstLoadYN = this.state.firstLoadYN;
		if (firstLoadYN) {
			return null;
		}

		var loggedInYN;
		var userID = this.state.userID;
		
		if (userID === 0) {
			loggedInYN = false;
		} else {
			loggedInYN = true;
		}
		
		return (
		<Router>
			<div className="App">
				<ToastContainer />
				<Topbar 
					userID={this.state.userID}
					email={this.state.email}
					firstName={this.state.firstName}
					lastName={this.state.lastName}
					sessionID={this.state.sessionID}
					appName="APPS"
					bkColor1="#4e2675"
					bkColor2="#f0f0f0"
					logoutClearVariables={this.logoutClearVariables}
					setLanguage={this.setLanguage}
					getLang={this.getLang}
				/>
        		<Routes>
          			<Route 
						path="/"
						element={
							<div>
							{!loggedInYN && (
								<Login
									managementBaseURLAPI={this.state.managementBaseURLAPI}
									setSessionID={this.setSessionID}
									setUserID={this.setUserID}
									serverGetUserData={this.serverGetUserData}
									getLang={this.getLang}
								/>
							)}
							{loggedInYN && (
								<AppList
									sessionID={this.state.sessionID}
									email={this.state.email}
									firstName={this.state.firstName}
									lastName={this.state.lastName}
									managementAppUserType={this.state.managementAppUserType}
									sailfinAppUserType={this.state.sailfinAppUserType}
									katafinAppUserType={this.state.katafinAppUserType}
									productionAppUserType={this.state.productionAppUserType}
									metersAppUserType={this.state.metersAppUserType}
									icartAppUserType={this.state.icartAppUserType}
									serviAppUserType={this.state.serviAppUserType}
									robotsAppUserType={this.state.robotsAppUserType}
									guardsAppUserType={this.state.guardsAppUserType}
									twinsAppUserType={this.state.twinsAppUserType}
									rentAppUserType={this.state.rentAppUserType}
									develAppUserType={this.state.develAppUserType}
									aiAppUserType={this.state.aiAppUserType}
									chatAppUserType={this.state.chatAppUserType}
									replyAppUserType={this.state.replyAppUserType}
									showToast={this.showToast}
									getLang={this.getLang}
								/>
							)}
							</div>
						}
					/>
          			<Route 
						path="/login"
						element={
							<Login
								managementBaseURLAPI={this.state.managementBaseURLAPI}
								setSessionID={this.setSessionID}
								setUserID={this.setUserID}
								serverGetUserData={this.serverGetUserData}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/apps"
						element={
							<AppList
								sessionID={this.state.sessionID}
								email={this.state.email}
								firstName={this.state.firstName}
								lastName={this.state.lastName}
								managementAppUserType={this.state.managementAppUserType}
								sailfinAppUserType={this.state.sailfinAppUserType}
								katafinAppUserType={this.state.katafinAppUserType}
								productionAppUserType={this.state.productionAppUserType}
								metersAppUserType={this.state.metersAppUserType}
								icartAppUserType={this.state.icartAppUserType}
								serviAppUserType={this.state.serviAppUserType}
								robotsAppUserType={this.state.robotsAppUserType}
								guardsAppUserType={this.state.guardsAppUserType}
								twinsAppUserType={this.state.twinsAppUserType}
								rentAppUserType={this.state.rentAppUserType}
								develAppUserType={this.state.develAppUserType}
								aiAppUserType={this.state.aiAppUserType}
								chatAppUserType={this.state.chatAppUserType}
								replyAppUserType={this.state.replyAppUserType}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
					/>					
         			<Route 
						path="/myaccount"
						element={
							<MyInfo
								userID={this.state.userID}
								email={this.state.email}
								firstName={this.state.firstName}
								lastName={this.state.lastName}
								phone={this.state.phone}
								language={this.state.language}
								managementAppUserType={this.state.managementAppUserType}
								sailfinAppUserType={this.state.sailfinAppUserType}
								katafinAppUserType={this.state.katafinAppUserType}
								productionAppUserType={this.state.productionAppUserType}
								metersAppUserType={this.state.metersAppUserType}
								icartAppUserType={this.state.icartAppUserType}
								serviAppUserType={this.state.serviAppUserType}
								robotsAppUserType={this.state.robotsAppUserType}
								guardsAppUserType={this.state.guardsAppUserType}
								twinsAppUserType={this.state.twinsAppUserType}
								rentAppUserType={this.state.rentAppUserType}
								develAppUserType={this.state.develAppUserType}
								aiAppUserType={this.state.aiAppUserType}
								chatAppUserType={this.state.chatAppUserType}
								replyAppUserType={this.state.replyAppUserType}
								companyID={this.state.companyID}
								companyName={this.state.companyName}
								createdTimestamp={this.state.createdTimestamp}
								setLanguage={this.setLanguage}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/myinfo/password"
						element={
							<MyInfoPassword
								managementBaseURLAPI={this.state.managementBaseURLAPI}
								sessionID={this.state.sessionID}
								userID={this.state.userID}
								serverGetUserData={this.serverGetUserData}
								showToast={this.showToast}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/myinfo/name"
						element={
							<MyInfoName
								managementBaseURLAPI={this.state.managementBaseURLAPI}
								userID={this.state.userID}
								firstName={this.state.firstName}
								lastName={this.state.lastName}
								serverGetUserData={this.serverGetUserData}
								getLang={this.getLang}
							/>
						}
					/>
         			<Route 
						path="/myinfo/phone"
						element={
							<MyInfoPhone
								managementBaseURLAPI={this.state.managementBaseURLAPI}
								userID={this.state.userID}
								phone={this.state.phone}
								serverGetUserData={this.serverGetUserData}
								getLang={this.getLang}
							/>
						}
					/>					
        		</Routes>
			</div>
    		</Router>
		);
	}
}

export default App;

/*
*/


