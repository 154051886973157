import React  from 'react';
import './App.css';
import MyInfoLang from './MyInfoLang.json';
  
import { useNavigate } from "react-router-dom";

class MyInfoPhone extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			phone: "",
		};

		this.onKeyDown = this.onKeyDown.bind(this);
		this.onPhoneChanged = this.onPhoneChanged.bind(this);		
		this.serverUpdatePhone = this.serverUpdatePhone.bind(this);
		this.onClickSaveButton = this.onClickSaveButton.bind(this);
		this.onClickCancelButton = this.onClickCancelButton.bind(this);
	}
	
	componentDidMount(){
		this.setState({
			phone: this.props.phone,
		});
	}

	onKeyDown(keyCode) {
		if (keyCode === 13) {
			this.onClickSaveButton();
		}
	}

	onPhoneChanged(value) {
		this.setState({phone: value});
	}
	
	async serverUpdatePhone(userID, phone) {

		var managementBaseURLAPI = this.props.managementBaseURLAPI;
		var url = managementBaseURLAPI + "/updateuserphone?userid=" + userID;
		url = url + "&phone=" + encodeURI(phone);

		const res = await fetch(url);
		await res.text();
	}
		
	onClickSaveButton() {
		this.serverUpdatePhone(this.props.userID, this.state.phone);
		this.props.serverGetUserData(this.props.userID);
		const navigate = this.props.navigate;
  		navigate("/myaccount");
	}
	
	onClickCancelButton() {
		const navigate = this.props.navigate;
  		navigate("/myaccount");
	}
	
	render() {

		var saveButtonYN = true;
		var phone = this.props.phone;

		return (
			<div className="ViewContainer">
			
				<div style={{height: "30px"}}></div>
				<div className="ViewTopTitle">{this.props.getLang(MyInfoLang, "updateYourPhone")}</div>
				<div className="ViewTopText">{this.props.getLang(MyInfoLang, "updateYourPhoneMessage")}</div>

				<div style={{height: "30px"}}></div>
				<div className="ViewBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="ViewBlockTitle">{this.props.getLang(MyInfoLang, "phone")}</div>
					<div style={{height: "20px"}}></div>
					<div className="ViewItemContainer">
						<div style={{height: "20px"}}></div>
						<fieldset className="ViewItemFieldset">
							<legend className="ViewItemLegend">{this.props.getLang(MyInfoLang, "phone")}</legend> 
							<input
								className="ViewItemInput" 
								id="phone" 
								type="text" 
								autoFocus={true}
								defaultValue={phone}
								onChange={(event) => this.onPhoneChanged(event.target.value)}
								onKeyDown={(event) => this.onKeyDown(event.keyCode)}
							/>
						</fieldset>
					</div>
					<div style={{height: "60px"}}></div>
					<div className="ViewContainerButtons">
						<div style={{width: "200px"}}></div>
						<button className="ViewCancelButton" onClick={this.onClickCancelButton}>{this.props.getLang(MyInfoLang, "cancelButton")}</button>
						<div style={{width: "20px"}}></div>
						<button className="ViewSaveButton" onClick={this.onClickSaveButton} disabled={!saveButtonYN}>{this.props.getLang(MyInfoLang, "saveButton")}</button>
					</div>
					<div style={{height: "50px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function MyInfoPhoneFunction(props) {
	const navigate = useNavigate();
	return <MyInfoPhone {...props} navigate={navigate} />;
}


