import React  from 'react';
import './App.css';
import MyInfoLang from './MyInfoLang.json';

import { useNavigate } from "react-router-dom";

class MyInfo extends React.Component {

	constructor(props) {

		super(props);
		
		this.getStringFromUserType = this.getStringFromUserType.bind(this);
		this.getStringFromLanguage = this.getStringFromLanguage.bind(this);
		this.renderOneRow = this.renderOneRow.bind(this);		
		this.handleLanguageClick = this.handleLanguageClick.bind(this);
		this.handleRowClick = this.handleRowClick.bind(this);
	}
	
	getStringFromUserType(userType) {
		
		var returnString = "";
		
		if (userType === 0) {
			returnString = this.props.getLang(MyInfoLang, "none");
		}
		
		if (userType === 1) {
			returnString = this.props.getLang(MyInfoLang, "user");
		}
		
		if (userType === 100) {
			returnString = "Administrator";
		}
		
		if (userType === 1000) {
			returnString = "Super Administrator";
		}
		
		return returnString;
	}
	
	getStringFromLanguage(language) {
		
		var returnString = "";
		
		if (language === "en") {
			returnString = "English";
		}
		
		if (language === "hr") {
			returnString = "Hrvatski";
		}
		
		return returnString;
	}
	
	handleLanguageClick() {

		var language = this.props.language;

		if (language === "en") {
			language = "hr";
			this.props.setLanguage(language);
			return;
		}

		if (language === "hr") {
			language = "en";
			this.props.setLanguage(language);
			return;
		}
	}
	
	handleRowClick(page) {
		if (page === "language") {
			this.handleLanguageClick();
			return;
		}
		if (page === "") {
			return;
		}
		const navigate = this.props.navigate;
  		navigate(page);
	}
	
	renderOneRow(title, appUserType, value, page) {

		if (appUserType < 1) {
			return null;
		}

		var gtString = ">";
		if (page === "") {
			gtString = "";
		}

		return (
			<div>
				<div className="MyInfoRowContainer" onClick={() => this.handleRowClick(page)}>
					<div className="MyInfoRowTitle">{title}</div>
					<div className="MyInfoRowText">{value}</div>
					<div className="MyInfoRowGreater">{gtString}</div>
				</div>
				<div style={{height: "1px"}}></div>
			</div>
		);
	}
	
	render() {

		var userID = this.props.userID;
		var email = this.props.email;
		var firstName = this.props.firstName;
		var lastName = this.props.lastName;
		var phone = this.props.phone;
		var managementAppUserType = this.props.managementAppUserType;
		var sailfinAppUserType = this.props.sailfinAppUserType;
		var katafinAppUserType = this.props.katafinAppUserType;
		var productionAppUserType = this.props.productionAppUserType;
		var metersAppUserType = this.props.metersAppUserType;
		var icartAppUserType = this.props.icartAppUserType;
		var serviAppUserType = this.props.serviAppUserType;
		var robotsAppUserType = this.props.robotsAppUserType;
		var guardsAppUserType = this.props.guardsAppUserType;
		var twinsAppUserType = this.props.twinsAppUserType;
		var rentAppUserType = this.props.rentAppUserType;
		var develAppUserType = this.props.develAppUserType;
		var aiAppUserType = this.props.aiAppUserType;
		var chatAppUserType = this.props.chatAppUserType;
		var replyAppUserType = this.props.replyAppUserType;
		var companyName = this.props.companyName;
		var language = this.props.language;
		//var createdTimestamp = this.props.createdTimestamp;

		if (userID === 0) {
			return null;
		}
		
		var name = firstName + " " + lastName;

		var managementAppUserTypeString = this.getStringFromUserType(managementAppUserType);
		var sailfinAppUserTypeString = this.getStringFromUserType(sailfinAppUserType);
		var katafinAppUserTypeString = this.getStringFromUserType(katafinAppUserType);
		var productionAppUserTypeString = this.getStringFromUserType(productionAppUserType);
		var metersAppUserTypeString = this.getStringFromUserType(metersAppUserType);
		var icartAppUserTypeString = this.getStringFromUserType(icartAppUserType);
		var serviAppUserTypeString = this.getStringFromUserType(serviAppUserType);
		var robotsAppUserTypeString = this.getStringFromUserType(robotsAppUserType);
		var guardsAppUserTypeString = this.getStringFromUserType(guardsAppUserType);
		var twinsAppUserTypeString = this.getStringFromUserType(twinsAppUserType);
		var rentAppUserTypeString = this.getStringFromUserType(rentAppUserType);
		var develAppUserTypeString = this.getStringFromUserType(develAppUserType);
		var aiAppUserTypeString = this.getStringFromUserType(aiAppUserType);
		var chatAppUserTypeString = this.getStringFromUserType(chatAppUserType);
		var replyAppUserTypeString = this.getStringFromUserType(replyAppUserType);
		
		var languageString = this.getStringFromLanguage(language);

		return (
			<div>

				<div style={{height: "30px"}}></div>
				<div className="MyInfoTopTitle">{this.props.getLang(MyInfoLang, "pluratoAccount")}</div>
				<div className="MyInfoTopText">{this.props.getLang(MyInfoLang, "infoMessage")}</div>
				<div style={{height: "30px"}}></div>

				<div className="MyInfoBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="MyInfoBlockTitle">Login info</div>
					<div style={{height: "20px"}}></div>
					{this.renderOneRow("Email", 1, email, "")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow(this.props.getLang(MyInfoLang, "password"), 1, "*****", "/myinfo/password")}
					<div style={{height: "10px"}}></div>
				</div>
				<div style={{height: "30px"}}></div>

				<div className="MyInfoBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="MyInfoBlockTitle">{this.props.getLang(MyInfoLang, "language")}</div>
					<div style={{height: "20px"}}></div>
					{this.renderOneRow(this.props.getLang(MyInfoLang, "language"), 1, languageString, "language")}
					<div style={{height: "10px"}}></div>
				</div>
				<div style={{height: "30px"}}></div>

				<div className="MyInfoBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="MyInfoBlockTitle">{this.props.getLang(MyInfoLang, "contactInfo")}</div>
					<div style={{height: "20px"}}></div>
					{this.renderOneRow(this.props.getLang(MyInfoLang, "name"), 1, name, "/myinfo/name")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow(this.props.getLang(MyInfoLang, "phone"), 1, phone, "/myinfo/phone")}
					<div style={{height: "1px"}}></div>
					{this.renderOneRow(this.props.getLang(MyInfoLang, "company"), 1, companyName, "")}
					<div style={{height: "10px"}}></div>
				</div>
				<div style={{height: "30px"}}></div>

				<div className="MyInfoBlockContainer">
					<div style={{height: "15px"}}></div>
					<div className="MyInfoBlockTitle">{this.props.getLang(MyInfoLang, "pluratoServicesPermissions")}</div>
					<div style={{height: "20px"}}></div>
					{this.renderOneRow("Management", managementAppUserType, managementAppUserTypeString, "")}
					{this.renderOneRow("Sailfin App", sailfinAppUserType, sailfinAppUserTypeString, "")}
					{this.renderOneRow("Rent App", rentAppUserType, rentAppUserTypeString, "")}
					{this.renderOneRow("Katafin App", katafinAppUserType, katafinAppUserTypeString, "")}
					{this.renderOneRow("Production App", productionAppUserType, productionAppUserTypeString, "")}
					{this.renderOneRow("Devel App", develAppUserType, develAppUserTypeString, "")}
					{this.renderOneRow("Chat App", chatAppUserType, chatAppUserTypeString, "")}
					{this.renderOneRow("AI App", aiAppUserType, aiAppUserTypeString, "")}
					{this.renderOneRow("Reply App", replyAppUserType, replyAppUserTypeString, "")}
					{this.renderOneRow("Meters App", metersAppUserType, metersAppUserTypeString, "")}
					{this.renderOneRow("iCart App", icartAppUserType, icartAppUserTypeString, "")}
					{this.renderOneRow("Servi App", serviAppUserType, serviAppUserTypeString, "")}
					{this.renderOneRow("Robots App", robotsAppUserType, robotsAppUserTypeString, "")}
					{this.renderOneRow("Guards App", guardsAppUserType, guardsAppUserTypeString, "")}
					{this.renderOneRow("Twins App", twinsAppUserType, twinsAppUserTypeString, "")}
					<div style={{height: "10px"}}></div>
				</div>

				<div style={{height: "100px"}}></div>
			</div>
		);
	}
}

export default function MyInfoFunction(props) {
	const navigate = useNavigate();
	return <MyInfo {...props} navigate={navigate} />;
}

