import React  from 'react';
import './Topbar.css';
import { FiMenu } from "react-icons/fi"
import Navbar from "../Navbar"
import TopbarPopupMenu from "./TopbarPopupMenu.js"

import { AiOutlineCamera } from "react-icons/ai"
import { BsPersonCircle } from "react-icons/bs"
import { HiOutlineCog } from "react-icons/hi"
import { MdOutlineDesignServices, MdAlternateEmail } from "react-icons/md"
import { MdGraphicEq } from "react-icons/md"

class Topbar extends React.Component {

	constructor(props) {

		super(props);
		
		this.state = {
			navbarOpenYN: false,
			showPopupMenuYN: false,
			popupMenuClosedByCircleYN: true,
		}

		this.handleNavbarToggle = this.handleNavbarToggle.bind(this);
		this.closeNavbar = this.closeNavbar.bind(this);
		this.handlePopupMenuToggle = this.handlePopupMenuToggle.bind(this);
		this.closePopupMenu = this.closePopupMenu.bind(this);
		this.renderLogo = this.renderLogo.bind(this);		
	}

	handleNavbarToggle() {
		var navbarOpenYN = this.state.navbarOpenYN;
		navbarOpenYN = !navbarOpenYN;
		this.setState({
			navbarOpenYN: navbarOpenYN
		});
	}

	closeNavbar() {
		this.setState({navbarOpenYN: false});
	}

	handlePopupMenuToggle() {

		var showPopupMenuYN = this.state.showPopupMenuYN;
		var popupMenuClosedByCircleYN = this.state.popupMenuClosedByCircleYN;
		
		if (!popupMenuClosedByCircleYN) {
			showPopupMenuYN = true;
		}

		showPopupMenuYN = !showPopupMenuYN;

		this.setState({
			popupMenuClosedByCircleYN: true,
			showPopupMenuYN: showPopupMenuYN,
		});
	}
	
	closePopupMenu() {
		this.setState({
			popupMenuClosedByCircleYN: false,
			showPopupMenuYN: false,
		});
	}
	
	renderLogo(appName) {
		
		if (appName === "APPS") {
			return (<BsPersonCircle style={{ color: "white", width: "50px", height: "50px" }} />);
		}

		if (appName === "PRODUCTION") {
			return (<HiOutlineCog style={{ color: "white", width: "50px", height: "50px" }} />);
		}
		
		if (appName === "DEVEL") {
			return (<MdOutlineDesignServices style={{ color: "white", width: "50px", height: "50px" }} />);
		}
		
		if (appName === "AI") {
			return (<MdGraphicEq style={{ color: "white", width: "50px", height: "50px" }} />);
		}
		
		if (appName === "REPLY") {
			return (<MdAlternateEmail style={{ color: "white", width: "50px", height: "50px" }} />);
		}

		if (appName === "GUARDS") {
			return (<AiOutlineCamera style={{ color: "white", width: "50px", height: "50px" }} />);
		}

		return null;
	}
	
	render() {

		var userID = this.props.userID;
		var email = this.props.email;
		var firstName = this.props.firstName;
		var lastName = this.props.lastName;
		var navbarOpenYN = this.state.navbarOpenYN;
		
		var showNavBarYN = true;
		if (userID === 0) {
			showNavBarYN = false;
		}
		
		var initialLetters = "";
		
		if (firstName !== "") {
			initialLetters = firstName.substring(0, 1);
		}
		
		if (lastName !== "") {
			initialLetters = initialLetters + lastName.substring(0, 1);
		}
		
		if (initialLetters === "") {
			initialLetters = email.substring(0, 1);
		}
		
		var appName = this.props.appName;
		
		return (
			<div className="TopbarContainer" style={{backgroundColor: this.props.bkColor1}}>
				{this.state.showPopupMenuYN && (
					<TopbarPopupMenu
						managementBaseURLAPI={this.props.managementBaseURLAPI}
						userID={this.props.userID}
						email={this.props.email}
						sessionID={this.props.sessionID}
						logoutClearVariables={this.props.logoutClearVariables}
						closePopupMenu={this.closePopupMenu}
						getLang={this.props.getLang}
						setLanguage={this.props.setLanguage}
					/>
				)}
				<div className="TopbarLeft">
					<div className="TopbarLeftColumn1">
						{showNavBarYN && (
							<button onClick={this.handleNavbarToggle}>
								<FiMenu style={{ color: "#7b7b7b", width: "30px", height: "30px" }} />
							</button>
						)}
						<Navbar
							navbarOpenYN={navbarOpenYN}
							twinsAppUserType={this.props.twinsAppUserType}
							closeNavbar={this.closeNavbar}
							setSnapshotListIndex={this.props.setSnapshotListIndex}
							logoutClearVariables={this.props.logoutClearVariables}
							getLang={this.props.getLang}
						/>
					</div>
					<div className="TopbarLeftColumn2">
						<div className="TopbarLogo1">
							{this.renderLogo(appName)}
						</div>
					</div>
					<div className="TopbarLogoColumn3">
						<div className="TopbarLogo2">PLURATO</div>
						<div className="TopbarLogo3">{appName}</div>
					</div>
				</div>
				{showNavBarYN && (
					<div className="TopbarRight" onClick={this.handlePopupMenuToggle}>
						<div className="TopbarInitialsContainer">
							<span className="TopbarInitials">{initialLetters}</span>
						</div>
					</div>
				)}
				{!showNavBarYN && (
					<div className="TopbarRight2">
					</div>
				)}
			</div>
		);
	}
}

export default Topbar;

